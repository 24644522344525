<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <Person
        v-for="(person, id) in primaryPeople"
        v-bind:key="id"
        v-bind:person="person"
        @selected="save"
        @edit="edit"
        :type="'pets_primary'"
      >
      </Person>
      <PersonAddQuad @addPerson="setNewPerson" :brotherSister="true">
      </PersonAddQuad>
      <PersonModal
        v-show="show.personModal"
        :show="show.personModal"
        :person="selectedPerson"
        :initialPerson="initialPerson"
        @close="closePersonModal"
      ></PersonModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import Person from '@/common/ui/Person'
import PersonModal from '@/views/pages/PeoplePage/PersonModal'
import PersonAddQuad from '@/common/ui/PersonAddQuad'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'

export default {
  name: 'PetsPrimary',
  mixins: [willStringHelpers],
  components: {
    NavQuestion,
    NavQuestionButtons,
    Person,
    PersonModal,
    PersonAddQuad
  },
  computed: {
    heading() {
      var string = 'Who would you want to look after your pets'
      if (this.partner && this.partner.pets_sole_primary)
        string +=
          ' if ' + this.firstWord(this.partner.full_name) + ' was unable to'
      return string + '?'
    },
    number() {
      if (this.partner) return '3'
      return '2'
    },
    subHeading() {
      return 'You must appoint at least one person.'
    },
    primaryPeople() {
      return this.$store.getters.people.filter(
        (person) => !person.under18 && !person.pets_sole_primary
      )
    },
    people() {
      return this.$store.getters.people
    },
    details() {
      return this.$store.getters.details
    },
    partner() {
      return this.$store.getters.partner
    },
    forwardTo() {
      return '/pets/cash'
    },
    backTo() {
      if (this.partner) return '/pets/partner'
      return '/pets/primary_option'
    },
    selectedPeople() {
      return !!this.people.filter((person) => person.pets_primary).length;
    },
    isValidated() {
      return !!(this.partner.pets_sole_primary || this.selectedPeople);
    }
  },
  data() {
    return {
      loading: false,
      selectedPerson: null,
      initialPerson: null,
      show: {
        personModal: false
      }
    }
  },
  methods: {
    save(person) {
      let personCopy = Object.assign({}, person)
      personCopy.pets_primary = !personCopy.pets_primary
      this.$store.commit('personAdd', personCopy)
    },
    edit(person) {
      this.selectedPerson = person
      this.show.personModal = true
    },
    closePersonModal() {
      this.selectedPerson = null
      this.show.personModal = false
      this.initialPerson = null
    },
    setNewPerson(value) {
      value.push('pets_primary')
      this.initialPerson = value
      this.show.personModal = true
      this.selectedPerson = null
    }
  }
}
</script>
